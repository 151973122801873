/* -----------------------------------
 * TYPOGRAPHY
 * ----------------------------------- */
pre,
.post-content code {
  background: rgb(45,45,45);
  color: #eee;
  white-space: pre-wrap !important;
}

.post-content li {
  margin-bottom: .5rem;
}
.post-content li:last-of-type {
  margin-bottom: 0;
}


