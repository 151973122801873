/*
Theme Name: at20
Text Domain: at20
Version: 1.4
Requires at least: 4.7
Requires PHP: 5.2.4
Description: Cool theme.
Tags: blog, toast
Author: Aaron W. Herd
Author URI: https://awesometoast.com
*/

// -----------------------------------
// CONFIG
// -----------------------------------

@import 'base/variables';
@import 'base/mixins';

// -----------------------------------
// RESET
// -----------------------------------

@import 'base/normalize';
@import 'base/reset';

// -----------------------------------
// COMPONENTS
// -----------------------------------
@import 'components/fonts';
@import 'components/scaffolding';
@import 'components/grid';
@import 'components/helpers';
@import 'components/forms';
@import 'components/tables';
@import 'components/navigation';
@import 'components/layout';
@import 'components/typography';
@import 'components/wordpress';
@import 'components/print';
