/* -----------------------------------
 * WORDPRESS
 * ----------------------------------- */
img.random-cat {
  min-width: 360px;
  height: auto;
}

.aligncenter {
  margin: 0 auto;
  display: block;
}
.alignleft {
  float: left;
  margin-right: 1rem;
}
.alignright {
  float: right;
  margin-left: 1rem;
}
.alignnone {
  float: none;
  clear: both;
}

/* Images & captions */
figure figcaption {
  text-align: center;
  font-style: italic;
  padding: .5rem;
}
.wp-block-image {
  margin-bottom: 1rem;
}

.post-content p > img {
  margin-bottom: 1.25rem
}

embed,
img.size-full,
.post-content img,
.wp-block-image img,
.post-content figure {
  max-width: 100%;
  height: auto;
}

.wp-block-image img {
  width: 100%;
  margin-bottom: -.4rem; /* Why is this necessary? */
}

// Code n suff
.wp-block-code {
  background: #000 !important;
}
.wp-block-code,
.code-toolbar {
  margin: 0 0 1.5rem;
}

// Override some prism.js styles
code[class*="language-"],
pre[class*="language-"] {
  max-width: 100% !important;
  overflow: auto !important;
  font-size: 14px !important;
}

/* --------------
 * BREADCRUMBS & PAGINATION (Default button styles are in _forms.scss)
 * -------------- */
.pagination-container {
  border-top: 1px solid rgba(38,57,89, .3);
  margin: 2.25rem 0 0 0;
  padding: 1.75rem 0 0 0;
}
.pagination-container .pagination-title {
  text-transform: uppercase;
}
.pagination .nav-links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
} 
.pagination .page-numbers {
  flex: 1 2 10%;
  text-align: center;
  margin: 0 .25rem;
  white-space: nowrap;
}
.pagination .page-numbers:first-child {
  margin-left: 0;
}
.pagination .page-numbers:last-child {
  margin-right: 0;
}
.pagination .page-numbers.current {
  background: transparent;
  color: #444;
  border: 1px solid #666;
  cursor: default;
}

@media screen and (max-width: $xs) {
  .pagination .page-numbers:nth-of-type(n+2) {
    display:none;
  }
  .pagination .page-numbers:nth-last-of-type(2),
  .pagination .page-numbers:last-of-type {
    display: block;
  }
}

@media screen and (max-width: $small) {
  .pagination .page-numbers:nth-of-type(n+3) {
    display:none;
  }
  .pagination .page-numbers:nth-last-of-type(-n+3),
  .pagination .page-numbers:last-of-type {
    display: block;
  }

  .pagination .page-numbers.next span,
  .pagination .page-numbers.prev span {
    display: none;
  }
}


/* --------------
 * POSTS
 * -------------- */
.post-meta-top {
  padding: 3rem 0 2.5rem;
}
main article:first-of-type .post-meta-top {
  padding-bottom: 4.5rem;
}
.post-separator {
  border-top: 1px solid lighten($border-color, 60%);
}
.post-meta-top .topcat {
  font-weight: 600;
  font-size: .75rem;
  text-transform: uppercase;
  margin:0 0 .75rem;
}
.post-meta-top .post-title {
  margin:0 0 1.25rem;
  padding:0;
}
.post-meta-top .post-submeta {
  font-size: .8rem;
  opacity: .7;
  text-transform: uppercase;
}
.post-meta-top .post-title {
  font-size: 2rem;
}
.post-submeta time {
  margin-left:2px;
}

.post-header .section-label {
  top:2rem;
  right:-1.5rem; /* scss: site-content-label-right */
}

.post-content .section-label {
  top:-2.25rem;
  right:-1.5rem; /* scss: site-content-label-right */
}




/* --------------
 * COMMENTS
 * -------------- */
.comment-zone {margin-top:4rem;}
.comment-zone .section-label {
  top:-.75rem;
  right:-1.5rem; /* scss: site-content-label-right */
}

.comments-header {
  padding-top:1px;
}
.comments .comment {
  padding: 1.5rem 1.25rem;
  padding-bottom: 1px;;
  margin: 1rem 0;
  border: 1px solid rgba(9,35,73, .2); /* scss: background lighten 80% */
}
.comment-author .avatar {
  float: left;
}
.comment-author .fn {
  display: block;
  margin: 6px 0 0 .5rem;
  float: left;
  font-weight: 600;
}
.comment-meta {
  padding: 1.5rem 1.25rem .5rem;
  margin: -1.5rem -1.25rem 1.5rem;
  background: rgba(9,35,73, .1);  /* scss: background lighten 90% */
}
.comment-metadata {
  display: inline-block;
  margin: .5rem 0 1rem;
}
textarea#comment {
  border: 1px solid rgba(9,35,73, .3); /* scss: background lighten 80% */
  background: #fefefe;
}
.comment-respond .comment-reply-title {
  margin-top: 2rem;
}
.comment-respond .form-submit {
  text-align: right;
}
