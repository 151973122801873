
/* -----------------------------------
 * MAIN NAVIGATION (SIDEBAR / NAVBAR)
 * ----------------------------------- */

$nav-item-padding: 1rem;

.layout-nav-col #nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.layout-nav-col .nav-item {
  padding: $nav-item-padding .5rem;
}
.layout-nav-col .nav-item.active {
  background: $background-alt;
}
.layout-nav-col .dropdown-menu {display: none}
.layout-nav-col .nav-item.active .dropdown-menu {
  display: block;
  margin: 1rem 0 -1rem !important;
}
.layout-nav-col .nav-link {
  font-size: 1.75rem;
  font-weight: 700;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #fff;
}
.layout-nav-col .nav-item.active > a:first-child {
  color: #fff;
  text-decoration-line: underline;
  text-decoration-style: double;
}

/* DROPDOWNS */
.layout-nav-col .dropdown-menu a.dropdown-item {
  font-weight: 400;
}
.layout-nav-col .dropdown-menu .nav-item.active a {
  text-decoration-style: solid;
}

#navbar-horizontal {display: none}

.nav-copyright {
  position: absolute;
  bottom: 1rem;
  color: $inverse-color;
  width: 100%;
  text-align: cener;
  font-size: .75rem;
  opacity: .55;
}



/* --------------
 * SIDEBAR MODE - Fixed left sidebar
 * -------------- */

$sidebar-md-width: 220px;
$sidebar-lg-width: 340px;
@media screen and (min-width: $small) {
  .layout-nav-col {
    min-width: $sidebar-md-width !important;
    width: $sidebar-md-width !important;
    max-width: $sidebar-md-width !important;
  }
  #navbar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $sidebar-md-width;
  }
} /* end min-width $medium */

@media screen and (min-width: $large) {
  .layout-nav-col {
    min-width: $sidebar-lg-width !important;
    width: $sidebar-lg-width !important;
    max-width: $sidebar-lg-width !important;
  }
  #navbar {
    width: $sidebar-lg-width !important;
  }
  #navbar .nav-link {
    font-size: 2rem;
  }
} /* end min-width $large */

.layout-nav-col .logo-zone {
  display: block;
  padding: 1.5rem;
}
.logo-zone .section-label {
  right: 2rem;
  bottom: .5rem;
}
#nav-menu .nav-section-label,
.nav-search {display:none}



/* --------------
 * NAVBAR MODE - Fixed top navbar
 * -------------- */
@media screen and (max-width: $medium), print {
  #logo-vertical {display: none}
  #navbar-horizontal {
    display: flex;
    height: $navbar-height;
    border-bottom: 1px solid $border-color;
  }
  
  #logo-horizontal {
    flex: 1;
    text-align: left;
  }
  #logo-horizontal img {
    max-height: 36px;
    margin: 1.25rem 1.5rem 0;
  }
  
  .logo-zone .section-label {
    right: 1.5rem;
    bottom: .5rem;
  }
  #nav-menu .nav-search {
    display: block;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $border-color;
  }  
  
  .layout-nav-col {
    padding: 0;
    max-height: $navbar-height;
  }
  .layout-nav-col .logo-zone .section-label {
    display: none;
  }
  .layout-nav-col .logo-zone {
    padding: 0;
    height: $navbar-height;
  }
  #navbar {position:relative}
  
  .navbar-toggle {
    width: $navbar-height;
    position: relative;
    background: $background-alt;
    border-left: 1px solid $border-color;
  }
  .navbar-toggle input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
  }
  .navbar-toggle-label {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 99;
    width: 38px;
    position: absolute;
    right: 28%;
    top: 50%;
    margin-top: -8px;
    padding-left: 4px;
  }
  .navbar-toggle .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
  }
  .navbar-toggle .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }
  .navbar-toggle .diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
  }
  .navbar-toggle .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }
  .navbar-toggle input[type=checkbox]:checked ~ .navbar-toggle-label > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
  }
  .navbar-toggle input[type=checkbox]:checked ~ .navbar-toggle-label > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
  }
  .navbar-toggle input[type=checkbox]:checked ~ .navbar-toggle-label > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
  }
  
  #nav-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: $navbar-height;
    right: 0;
    bottom: 0;
    right: -100vw;
    z-index: 999;
    background: $nav-background;
    transition: transform .4s ease;
    overflow-y: scroll;
  }

  // Prevent the main body from scrolling while the menu is in
  body.scroll-lock {
    overflow: hidden;
  }

  // Make sure the nav menu scrolls when a submenu is open (otherwise can cut off last item)
  #nav-menu.in > ul {
    padding-bottom:4.5rem !important;
  }

  #nav-menu.in {
    transform: translateX(-100vw);
  }
  
  #nav-menu .nav-section-label {
    bottom: -2rem;
    left: 50%;
    margin-left: -30px;
  }
  
  #nav-menu.in .nav-section-label {
    bottom: -1rem;
    display: block;
    position: relative;
  }
  
  #nav-menu .nav-link {
    font-size: 3rem;
  }

  .nav-copyright {display: none}
  .layout-footer {display: block}

} /* end max-width $medium */
