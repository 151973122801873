/* -----------------------------------
 * LAYOUT
 * ----------------------------------- */

main {
  padding-top: $main-padding-top;
}

main.portfolio {
  padding-top: $port-padding-top;
}

#site-content {
  min-width:0; /* Important for fixing that absolute maddening quirk with flex and <pre>s */
}


// HEADER & FOOTER BARS
.layout-header,
.layout-footer {
  position: fixed;
  width: 100%;
  height: 10px;
  display: none;
}
.layout-header {
  background: rgb(2,22,59); /* scss: background-alt */
  top:0;
  height:3rem;
  z-index:5;
}
.layout-footer {
  background: transparent;
  bottom: 0;
  height: 2.5rem;
  text-align: center;
  background: #fefefe; /* $content-background */
  padding: .5rem 0;
  font-size: .8rem;
}

.section-label {
  position: absolute;
}

// MAIN LAYOUT STUFF
#layout-row {
  display: flex;
  min-height: 100vh;
}

.layout-col {
  flex: 25%;
}

// Left-hand spacer only appears on larger screens
.layout-spacer-col {
  flex: 10%;
  max-width: 66px;
	background: $background-alt;
}

.layout-nav-col {
  text-align: center;
  background: rgb(2,29,68); /* scss: nav background */
  min-width: $widescreen-sidebar-min-width;
  max-width: $widescreen-sidebar-max-width;
}

// MAIN COL
// Container for flexboxes
@media screen and (max-width: 1860px) {
  #layout-row {
    max-width:1860px;
  }
}
.layout-main-col {
  flex: 2 2 30%;
  max-width: $medium;
  background: #fefefe;
  padding: 3rem;
  color: #111;
}
.layout-main-col.layout-main-col-xl {
  max-width: $xl;
}
.layout-main-col.page {
  border-right:1px solid $border-color;
}


@media screen and (max-width: 992px), print {
  #layout-row {
    flex-direction: column;
    width: 100%;
  }

  .layout-col {
    flex: 100;
    max-width: unset;
  }
  
  .layout-spacer-col {
    display: none;
  }
} /* end max-width 992px */


@media screen and (max-width: 767px) {
  #layout-row {
    flex-direction: column;
  }
}


// SIDEBAR
.layout-aside-col {
  flex: 20%;
  max-width: 340px; /* scss: sidebar max width */
  background: rgb(9,35,73);
  padding: 8.5rem 1.5rem 2rem;
  background: url('/wp-content/themes/at20/assets/images/tile-sidebar-1.png') repeat !important;
  min-width: $widescreen-sidebar-min-width;
  max-width: $widescreen-sidebar-max-width;
}
.layout-aside-col .aside-heading {
  margin-top: 0;
  padding-top: 0;
  color: $inverse-color;
  text-shadow: 2px 2px 2px rgba(0,0,0,.25);
}
.layout-aside-col %forms:focus {
  border: 1px solid white;
  box-shadow: 0 0 4px rgba(255,255,255,.9);
}
@media screen and (max-width: $medium) {
  .layout-aside-col {display: none}
  .layout-main-col {padding-bottom: 9rem !important}
}
@media screen and (min-width: $xl) {
  $xl-aside-padding: 2.5rem;
  .layout-aside-col {
    padding-left: $xl-aside-padding;
    padding-right: $xl-aside-padding;
  }
  .layout-aside-col .input-group-append {
    display:inline-block;
  }
}


// TOASTER VOID
.layout-toaster-void,
.toaster-continuum {
  display: none;
  background: none; /* bg will lazy load when screen size is appropriate */
  background-color: $background-alt;
}

@media screen and (min-width: 1860px) {
  .layout-toaster-void {
    display: flex;
    flex: 1;
    border-left: 1px solid transparentize($border-color, .15);
  }
  .toaster-continuum {
    display:block;
    height:100%;
    width:100%;
    background: repeat-y 75% 50%/90% url('/wp-content/themes/at20/assets/images/isometric-toasters.svg');
    opacity: .1;
  }
}
.layout-toaster-void .section-label {
  top:10.5rem;
  left:1rem;
  padding:1rem 1rem .5rem;
  background:rgb(2,22,59);
}
.layout-toaster-void {
  background-color: $background-alt;
}



/* --------------
 * UTILITY & CONTACT PAGES
 * -------------- */
.page.contact .flex-row,
.page.utility-page .flex-row {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
}
.flex-large {
  padding:0 0 0 2rem;
}
.social-item {
  font-size:1.15rem;
}

.page.utility-page .utility-page-img {
  margin-right:2rem;
}
.page.utility-page .utility-page-heading {
  padding-top: 3rem;
}

@media screen and (min-width: 992px) and (max-width: 1600px) {
  .page.utility-page .utility-page-img img{
    max-width: 100%;
    height: auto;
  }
  .page.utility-page .half:first-of-type {
    flex: 100%;
    min-width: 220px;
  }
}
@media screen and (max-width: 992px) {
  .page.contact .flex-row,
  .page.utility-page .flex-row {
    flex-direction: column;
  }
  .socials-col {
    padding: 0 0 2rem;
  }
  .flex-large {
    padding: 0;
  }

  .page.utility-page {
    text-align: center;
  }
 .page.utility-page .utility-page-img {
    margin: 0;
  }
  .page.utility-page .utility-page-heading {
    padding-top: 2rem;
  }
}


/* --------------
 * LUMINOUS LIGHTBOX
 * https://github.com/imgix/luminous
 * -------------- */
.lum-lightbox {
  z-index: 199;
}
.lum-lightbox-inner {
  background: rgba(0, 0, 0, 0.5);
}
.lum-lightbox-image-wrapper {
  margin: 0 !important;
  padding: 1rem 0 0 0;
  background: none;
  position: relative;
}
.lum-lightbox .lum-lightbox-caption {
  max-width: 92vw;
  padding: .5rem 3rem 1rem;
  font-size: .85rem;
  font-weight: 500;
  letter-spacing: -0.01em;
}
.lum-lightbox .lum-gallery-button {
  top: unset;
  bottom: 0;
  height: 2rem;
}
.lum-lightbox .lum-gallery-button:hover,
.lum-lightbox .lum-gallery-button:active,
.lum-lightbox .lum-gallery-button:focus {
  background: none;
  border: none;
}
.lum-lightbox .lum-gallery-button:hover::after {
  border-color: #ee011c !important;
}
.lum-lightbox .lum-lightbox-image-wrapper .lum-img {
  max-width: 92vw;
}
.lum-lightbox .lum-close-button {
  top: 2vh;
  right: 4vw;
  cursor: pointer;
}


/* --------------
 * BACK TO TOP BUTTON
 * -------------- */
.to-top {
  position: fixed;
  bottom: 0;
  right: 0px;
  width: 0px;
  height: 0px;
  color: #fff;
  border-style: solid;
  border-width: 0 0 60px 60px;
  border-color: transparent transparent rgba(18, 24, 24, .4) transparent;
  z-index: 100;
}
.to-top span {
  position: relative;
  overflow: hidden;
  font-size: 20px;
  bottom: -30px;
  display: inline-block;
  left: -50px;
  cursor: pointer;
  height: 42px;
  width: 60px;
  line-height: 30px;
  text-align: center;
  z-index: 101;
}
.to-top a {
  position: absolute;
  right: 0;
  display: inline-block;
  width: 60px;
  height: 60px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.to-top .first {
  top: 0px;
}
.to-top .hover {
  top: 20px;
}
.to-top .first i {
  color: #fff;
}
.to-top:hover {
  cursor: pointer;
}
.to-top:hover span > a.first {
  top: -28px;
}
.to-top:hover span > a.hover {
  top: 0;
}