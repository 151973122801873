@media print {
  .navbar-toggle,
  .layout-aside-col,
  .layout-spacer-col,
  .layout-toaster-void,
  .pagination-container,
  .login-edit-button,
  .comment-respond,
  .comments-wrapper .section-label,
  #back-to-top {display:none}
  .layout-nav-col {background: #fff}
  #navbar-horizontal img {filter:invert(1)}
  a {text-decoration: underline; color:#000}
  .layout-main-col {
    border:none;
  }
}
